@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
/* Add gratitude button and text screen   */
.cards-space-affirmation {
  width: 18 rem;
  margin-top: 20 px !important;
  cursor: pointer;
}
.cursor {
  cursor: pointer;
}
.pods-cards-shadow {
  box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 16px 32px -4px rgb(0 3 82 / 24%);
  border-radius: 16px;
}
.h2-heading {
  color: var(--portal-theme-primary);
}
.pods-active-members {
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: -20px !important;
  color: #858585;
}
.pods-image {
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}
.memory-heading {
  padding-top: -1px !important;
}

.padding-screen {
  padding-left: 12px;
  padding-right: 24px;
}
@media only screen and (max-width: 769px) {
  .cards-space-affirmation {
    width: 18 rem;
    margin-top: 20 px;
    margin-left: 65 px;
  }
}

/*--------------------------------------------------------------
# Fonts
--------------------------------------------------------------*/

:root {
  /* --portal-theme-primary: #198ba8; */
  /* --portal-theme-secondary: #145b6c;
  --portal-theme-primary: #1a93a9;
  --input-field-backGround: #eaeaea;
  --text-disable: #858585;
  --back-arrow: rgba(0, 171, 85, 0.08);
  --white: #ffffff; */
}
:root {
  --portal-theme-primary: #f6bd4b;
  --portal-theme-secondary: rgba(175, 151, 102, 0.199);
  --text-primary-color: white;
  --text-secondary-color: rgb(150, 150, 150);

  --background-primary-color: black;
  --background-secondary-color: black;

  --sidebars-text-color: white;
  --sidebars-active-color: #f6bd4b;
  --sidebars-hover-color: #f6bd4b;
  --sidebars-background-color: #1d1c1d;

  --popup-background-color: #000000;
  --popup-text-color: white;

  --input-text-color: #fff;
  --input-placeholder-color: #fff;
  --input-background-primary: #fff;
  --input-background-secondary: #fff;
  --icons-color-allover: #f6bd4b;

  --button-background-color: #1c1c1c;
  --button-text-color: #f6bd4b;
  --button-outlined-color: #f6bd4b;
  --button-hover-color: "#f6bd4b";
  --audio-color: "#1d1c1d";
  --calendar-selected-box: #8b8b8b;
}

body {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  background-color: black !important;
  background-color: var(--background-primary-color) !important;
  color: #fff !important;
  color: var(--input-text-color) !important;
}
/* color scheme  */
/* side bar active color */
.css-zggrcv-MuiButtonBase-root-MuiListItemButton-root {
  color: #f6bd4b;
  color: var(--portal-theme-primary);
  background-color: rgba(175, 151, 102, 0.199);
  background-color: var(--portal-theme-secondary);
}
.css-zggrcv-MuiButtonBase-root-MuiListItemButton-root:before {
  background-color: #f6bd4b;
  background-color: var(--portal-theme-primary);
}
.css-7tp7sf-MuiButtonBase-root-MuiListItemButton-root {
  color: #fff;
  color: var(--input-text-color);
}
.css-1mcnwpj-MuiList-root {
  background-color: #1d1c1d;
  background-color: var(--sidebars-background-color);
}
.css-1x1uhi3-MuiButtonBase-root-MuiListItemButton-root {
  background-color: #1d1c1d;
  background-color: var(--sidebars-background-color);
  color: #fff;
  color: var(--input-text-color);
}
/* pop up menu background color */
.css-1d9xd7g-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper,
.css-bgcvkz,
.css-1ft4iha,
.css-f0u5qy,
.css-13pz97u,
.makeStyles-paper-3 {
  /* background-color: var(--sidebars-background-color) !important; */
  background: white !important;
  color: #fff !important;
  color: var(--input-text-color) !important;
}
.css-1bwuv8o {
  color: #fff !important;
  color: var(--input-text-color) !important;
}
/* side bar drop down text color */
.css-15az7i7 {
  color: #fff !important;
  color: var(--input-text-color) !important;
}
/* input text color and dropdown */
.css-n26ql7-MuiInputBase-input-MuiOutlinedInput-input,
.css-9q3kl4-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
.css-1szfdds,
.css-1vghvaa {
  color: #fff;
  color: var(--input-text-color);
}
.css-1n7l3yx-MuiButtonBase-root-MuiListItemButton-root,
.css-16k078d-MuiButtonBase-root-MuiListItemButton-root,
.css-tr2x06-MuiButtonBase-root-MuiListItemButton-root,
.css-cnafzx-MuiButtonBase-root-MuiListItemButton-root,
.css-e9crry-MuiInputBase-input-MuiOutlinedInput-input {
  color: #fff;
  color: var(--input-text-color);
}
.css-1qzvkqg-MuiTypography-root-MuiDialogTitle-root {
  color: #f6bd4b;
  color: var(--portal-theme-primary);
}
.reply-anchor a:hover {
  text-decoration: none !important;
  color: white !important;
}
/* account popup icon color */
.css-hsw3r1 {
  color: #f6bd4b;
  color: var(--portal-theme-primary);
}
.css-vubbuv {
  fill: #f6bd4b;
  fill: var(--portal-theme-primary);
}
.css-wj2255-MuiButtonBase-root-MuiButton-root:hover {
  background-color: #f6bd4b;
  background-color: var(--portal-theme-primary);
}

/* top sticky bar color */
.css-1yv00nn-MuiPaper-root-MuiAppBar-root,
.css-1nipdsk {
  background-color: black;
  background-color: var(--background-primary-color);
}
/* tables background color */
/* .css-n1f7dt-MuiPaper-root-MuiCard-root,
.css-oau2hf-MuiPaper-root,
.css-9dfmxx,
.css-1c1nuln {
  background-color: var(--sidebars-background-color);
} */
/* table text color */
.css-qv3jka,
.css-qv3jka.Mui-active,
.css-193pwxe,
.css-sjdedl,
.css-1f4e4r3 {
  color: #fff;
  color: var(--input-text-color);
}

.css-184p1yy,
.css-zuwxiq {
  color: #fff;
  color: var(--input-text-color);
}

/* calendar text color */
.css-1cwi9p6-MuiPaper-root {
  /* background-color: var(--sidebars-background-color); */
  color: #fff;
  color: var(--input-text-color);
}
.css-rbocvz-MuiButtonBase-root-MuiPickersDay-root:hover {
  background-color: #f6bd4b;
  background-color: var(--portal-theme-primary);
}
.css-s80gtz-MuiButtonBase-root-MuiPickersDay-root {
  background-color: #1d1c1d;
  background-color: var(--sidebars-background-color);
  color: #fff;
  color: var(--input-text-color);
}
.css-1nuss9t {
  color: #fff;
  color: var(--input-text-color);
}
/* .css-oau2hf-MuiPaper-root {
  background-color: var(--sidebars-background-color);
  color: var(--input-text-color);
} */
/* table bottom text */
.css-qerafn-MuiTablePagination-root {
  color: #fff;
  color: var(--input-text-color);
}
/* table text color */
.css-1gf7s20-MuiTableCell-root,
.css-5hc1ig-MuiTableCell-root,
.css-66mibm-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active,
.css-olh58z-MuiInputBase-root-MuiOutlinedInput-root,
.css-ph2jbk-MuiInputBase-root-MuiOutlinedInput-root,
.css-9w6cv0-MuiInputBase-root-MuiOutlinedInput-root {
  color: #fff;
  color: var(--input-text-color);
}
/* audio player color */
audio::-webkit-media-controls-panel {
  background-color: "#1d1c1d" !important;
  background-color: var(--audio-color) !important;
  border-radius: 50px;
}
::-webkit-media-controls-time-remaining-display,
audio::-webkit-media-controls-current-time-display {
  color: #f6bd4b;
  color: var(--portal-theme-primary);
}
::-webkit-media-controls-enclosure {
  background-color: rgba(175, 151, 102, 0.199);
  background-color: var(--portal-theme-secondary);
}
/* audio player color end */
/* three dots color 1st detail pages 2nd for tables */
.css-admp4s-MuiButtonBase-root-MuiIconButton-root {
  color: #f6bd4b;
  color: var(--portal-theme-primary);
}
.css-i4bv87-MuiSvgIcon-root {
  color: #f6bd4b !important;
  color: var(--portal-theme-primary) !important;
}
/* tables popup menu color and background color */
.css-vuwtl7-MuiPaper-root-MuiPopover-paper,
.css-1uz9yhf {
  /* background-color: var(--sidebars-background-color); */
  color: #fff;
  color: var(--input-text-color);
}
/* small arrow color on popup */
.css-98b6ta {
  background: #1d1c1d;
  background: var(--sidebars-background-color);
}
/* account popover background color */
/* .css-1ti1ykt-MuiPaper-root-MuiPopover-paper {
  background-color: var(--sidebars-background-color);
  color: var(--popup-text-color);
} */
.css-1fuveia-MuiPaper-root-MuiPopover-paper {
  /* background-color: var(--sidebars-background-color); */
  color: #fff;
  color: var(--input-text-color);
}
/* confirmation popup */
.css-189n0i1-MuiPaper-root-MuiDialog-paper {
  background-color: #000000 !important;
  background-color: var(--popup-background-color) !important;
  color: white;
  color: var(--popup-text-color);
}
/* sidebar icons color */
.css-1gxhmaf-MuiListItemIcon-root,
.css-r13mc8,
.css-1g0vwx4 {
  color: #f6bd4b;
  color: var(--portal-theme-primary);
}
/* sidebar background color */
.css-6lzitz-MuiPaper-root-MuiDrawer-paper {
  background-color: #1d1c1d;
  background-color: var(--sidebars-background-color);
}
/* login page colors */
.css-17mra34-MuiPaper-root-MuiCard-root {
  background-color: black;
  background-color: var(--background-primary-color);
  color: #fff;
  color: var(--input-text-color);
}
.css-yzm7vx,
.css-1v6giby {
  color: #fff;
  color: var(--input-text-color);
}
.css-13d5z5v:hover {
  background-color: #f6bd4b;
  background-color: var(--portal-theme-primary);
}
.css-1v6giby {
  background-color: black !important;
  background-color: var(--background-primary-color) !important;
}
.css-105feo8-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root:hover {
  background-color: #f6bd4b;
  background-color: var(--portal-theme-primary);
}
/* .card {
  background-color: var(--sidebars-background-color);
} */
/* color scheme end  */
/* autocomplete chip text color and popup background*/
.css-1esf93k-MuiButtonBase-root-MuiChip-root,
.css-11mo73z-MuiInputBase-input-MuiOutlinedInput-input {
  color: #fff;
  color: var(--input-text-color);
}
.css-kd0yc-MuiPaper-root-MuiAutocomplete-paper {
  /* background-color: var(--sidebars-background-color); */
  color: #fff;
  color: var(--input-text-color);
}

/* sidebar background color */
.css-6lzitz-MuiPaper-root-MuiDrawer-paper,
.css-1al2aw7 {
  background-color: #1d1c1d;
  background-color: var(--sidebars-background-color);
}
.css-1rkgz8u {
  color: #fff;
  color: var(--input-text-color);
}
/* top sticky header */
.css-1nipdsk {
  color: black;
  color: var(--background-primary-color);
}
.css-1umppoh {
  background-color: black;
  background-color: var(--background-secondary-color);
}
.menuActive {
  color: #f6bd4b !important;
  color: var(--sidebars-active-color) !important;
  background-color: rgba(175, 151, 102, 0.199);
  background-color: var(--portal-theme-secondary);
}
.tracking_code_heading {
  margin-top: 10px;
  margin-bottom: 5px;
}
.responsive-iframe {
  /* position: absolute; */
  /* top: 0;
  left: 0;
  bottom: 0;
  right: 0; */
  width: 100%;
  /* height: 100%; */
}

h1 {
  font-size: 40px;
  color: #f6bd4b;
  color: var(--portal-theme-primary);
}
.margin-botton {
  margin-top: 15px;
}
h2 {
  font-size: 30px;
  color: #f6bd4b;
  color: var(--portal-theme-primary);
}
h3 {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 0.5rem !important;
}
h4 {
  font-size: 1rem;
  font-weight: 500;
}
h5 {
}
h6 {
}
/* edit profile screen  */
.cards-edit,
.profile-cards {
  /* background-color: var(--background-secondary-color); */
  border: none;
  box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 16px 32px -4px rgb(0 3 82 / 24%);
  cursor: pointer;
  transition: all 0.5s;
}
.cards-edit {
  width: 850px;
}
.edit-profile-icon-position {
  position: absolute;
  right: -10px;
  top: 66px;
}
.edit-profile-icon img {
  border-radius: 50px;
}
.edit-profile-icon {
  position: relative;
}
/* edit profile screen end */
.MuiDrawer-paperAnchorRight {
  width: 400px !important;
}
.forms-drawer .simplebar-wrapper {
  height: 100% !important;
}
.simplebar-wrapper {
  height: 400px;
  overflow: hidden;
}
button.disabled:hover {
  cursor: not-allowed;
}
.calender-events-text {
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  padding: 2px 6px;
}
.calender-events-box {
  color: var(--white);
  padding: 20px;
}
.calender-events-box {
  margin-top: 30px;
}
.Ninty-Day-trackerList-dots .remove-border,
.affirmation-dots .remove-border,
.set-display-inline .remove-border {
  /* color: hsla(40,31%,54%,.199); */
  color: rgba(175, 151, 102, 0.199);
  color: var(--portal-theme-secondary);
  display: inline;
  height: 36px;
  position: absolute;
  right: 5px;
  text-align: center;
  width: 36px;
  top: 15px;
}
.a-style {
  text-decoration: none;
}
.tracking_code_heading {
  margin-top: 10px;
  margin-bottom: 5px;
}
.a-style:hover {
  color: white !important;
}
.menu-background-circle {
  height: 35px;
  width: 35px;
  border-radius: 51px;
  /* background-color: #145b6c29; */
}
.answer-ticket {
  background-color: #007bff;
  color: white;
  width: 80px;
}
.solved-ticket {
  background-color: #28a745;
  color: white;
  width: 80px;
}
.trash-ticket {
  background-color: #ff0000;
  color: white;
  width: 80px;
}
/* .circle-image img {
  border-radius: 100%;
  cursor: pointer;
  height: 60px;
  padding: 9px;
  width: 60px;
  z-index: 1;
} */
.circle-image {
  border-radius: 100%;
  cursor: pointer;
  height: 60px;
  padding: 9px;
  width: 60px;
  z-index: 1;
}
.preview img {
  border-radius: 5px;
  display: inline;
  height: 76px;
  margin-right: 10px;
  width: 76px;
}
.pending-ticket {
  background-color: #ffc107;
  color: white;
}
.fc .fc-daygrid-day-bottom {
  margin-top: 30px !important;
}
.new-memories .MuiFormControl-root {
  width: 100%;
}
.normal-text {
  font-size: 14px;
  font-weight: 500;
}

a:hover {
  text-decoration: none !important;
  color: #f6bd4b !important;
  color: var(--portal-theme-primary) !important;
}
a {
  color: #f6bd4b;
  color: var(--portal-theme-primary);
  text-decoration: underline;
}

legend {
  float: unset;
}
.text-area-task {
  background-color: var(--input-field-backGround);
  outline: none;
}

.programm-card h3 {
  color: #f6bd4b;
  color: var(--portal-theme-primary);
}
.programm-card p span {
  color: var(--text-disable);
}
.lesson-card h4 {
  color: #f6bd4b;
  color: var(--portal-theme-primary);
}

.simplebar-wrapper {
  height: 400px;
  overflow: hidden;
}
.simplebar-content-wrapper {
  overflow: scroll;
}
.programme-card-desc-muted {
  position: relative;
}
.recording-card-date {
  position: absolute;
  width: 100%;
  bottom: 20px;
}
.wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}
.div-style {
  border-bottom: 1px solid #cacaca;
  padding: 8px;
}
/* .css-69ikgk-MuiButtonBase-root-MuiButton-root:hover {
  background-color: var(--portal-theme-primary);
} */
.css-69ikgk-MuiButtonBase-root-MuiButton-root:hover,
.css-cplqmi:hover {
  background-color: #f6bd4b;
  background-color: var(--portal-theme-primary);
}

.player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.member-profile-image {
  border-radius: 10px;
}
.model-button-hover:hover {
  background-color: rgba(26, 147, 169, 0.08);
}
/* .download-icon {
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.resource-card {
  position: relative;
} */
.download-icon .icon-style {
  color: black;
  color: #f6bd4b;
  color: var(--portal-theme-primary);
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  margin-top: 50px;
  right: 10px;
}
.download-icon {
  text-align: end;
  margin-bottom: 20px;
  margin-top: 20px;
}
.icon-style {
  float: right;
}
.menu-icon-resource {
  position: absolute;
  top: 0px;
}
.css-69ikgk-MuiButtonBase-root-MuiButton-root:hover {
  background-color: none;
}

.task-label {
  font-family: "Montserrat", sans-serif;
}

textarea:hover {
  box-shadow: none;
}

.form-control:focus {
  box-shadow: none;
  border-color: #f6bd4b;
  border-color: var(--portal-theme-primary);
}

/* .programme-title{
    color: #198BA8;
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 0.5rem!important;
} */

.programme-card-desc {
  margin-top: 0px !important;
  font-size: 14px;
}

.programme-duration {
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 0px !important;
}

.lesson-notes-title {
  font-size: 14px;
  font-weight: 600;
  color: var(--text-disable);
}

.manage-program-chip-success {
  width: 66px;
  text-align: center;
}
.anchor-style {
  text-decoration: none;
  cursor: pointer;
  color: #fff;
  color: var(--input-text-color);
}
.no-access-string {
  width: 100%;
  text-align: center;
  padding: 20px;
}
.tox .tox-statusbar a,
.tox .tox-statusbar__path-item,
.tox .tox-statusbar__wordcount {
  display: none;
  color: rgba(34, 47, 62, 0.7);
  text-decoration: none;
}
/* .programmes-heading{
    font-size: 40px;
    color: #198BA8;
} */

.card-button {
  /* position: absolute;
    bottom: 15px; */
}

.programme-content {
  padding: 15px 15px 20px 15px;
}
.icon-color {
  color: #f6bd4b;
  color: var(--portal-theme-primary);
  cursor: pointer;
}
.cursor-style {
  cursor: pointer;
}

.lesson-content {
  padding: 15px 15px 20px 15px;
}

.hovr-effect:hover {
  color: black;
}
.css-sk5b5y:hover,
.css-1xh8ord-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root:hover {
  background-color: #f6bd4b;
  background-color: var(--portal-theme-primary);
}

/* .small-contained-button {
  background-color: #1c1c1c !important;
  background-color: var(--button-background-color) !important;
  border: 1px solid #f6bd4b;
  border: 1px solid var(--button-outlined-color);
  border-radius: 5px;
  color: var(--button-text-color);*/
/* padding: 8px 16px;
} */
.small-contained-button {
  background: #1c1c1c !important;
  background: var(--button-background-color) !important;
  border: 0px solid #f6bd4b;
  border: 0px solid var(--button-outlined-color);
  border-radius: 5px;
  color: #f6bd4b;
  color: var(--button-text-color);
  padding: 8px 16px;
}
.small-outlined-button {
  background-color: #fff;
  padding: 8px 16px;
  color: #f6bd4b;
  color: var(--portal-theme-primary);
  border-radius: 5px;
  border: 1px solid #f6bd4b;
  border: 1px solid var(--portal-theme-primary);
}
/* clock numbers color  */
.css-1nwnru8 {
  color: #fff;
  color: var(--input-text-color);
}
.css-1xvfevw {
  color: #ffffff6b;
}
.filter-icon {
  color: #f6bd4b;
  color: var(--portal-theme-primary);
}
.member-profile {
  /* overflow-x: ; */
}
.css-zo22pu-MuiDataGrid-root,
.css-1u4sujl {
  color: #fff;
  color: var(--input-text-color);
}
.form-label-lesson {
  font-weight: bold;
}

.feedback-programme {
  font-size: 25px;
}

.normal-font {
  font-size: 14px;
  font-weight: 500;
}

.feedback-programme-header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.feedback-programme-text {
  padding: 10px;
  font-weight: bold;
  margin-bottom: 0;
}

.bold-heading {
  font-weight: bold;
}

.section-space {
  margin-top: 0.75rem !important;
}

.rating-stars {
  float: right;
  padding-bottom: 15px;
  padding-right: 15px;
}

.program-font-size {
  font-size: 14px;
  font-weight: 500;
}

.heading-subtitle {
  font-size: 14px;
  font-weight: 700;
}

.icon-style {
  color: var(--text-disable);
  float: right;
}

.icon-style:hover {
  color: #f6bd4b;
  color: var(--portal-theme-primary);
  border-radius: 50%;
}
/* 
.icon-button-style{
    float: right;
} */

.lesson-card {
  cursor: pointer;
  box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 5px 10px -4px rgb(0 3 82 / 24%);
  border-radius: 10px;
}
.css-1d9xd7g-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  box-shadow: 0 0 2px 0 rgba(169, 170, 192, 0.24),
    0 5px 10px -4px rgba(211, 211, 214, 0.24);
}

.back-screen-button {
  background-color: var(--back-arrow);
  margin-bottom: 10px;
}
.goal-button-color {
  color: black;
}

.goal-gallery {
  height: 200px;
  background-color: #c2c2c23d;
  border-radius: 10px;
}
.css-13isf56-MuiInputBase-input-MuiOutlinedInput-input {
  color: #fff;
  color: var(--input-text-color);
}

.media-margin {
  margin-top: 0.75rem !important;
}

.back-arrow-margin {
  margin-bottom: 10px;
}

.css-19kzrtu {
  padding: 0px 0px;
}
.custom-video-player {
  height: 100% !important;
}

@media only screen and (max-width: 375px) {
  .custom-video-player {
    height: 100% !important;
  }
  .lesson-heading {
    padding-top: 10px;
  }
  .card-spacing {
    margin-top: 0.75rem;
  }
  .mobile-margin {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .lesson-card-image {
    height: 100%;
  }
}

/*=================================  full calender  =======================*/

.calender-events-box {
  color: #fff;
  color: var(--input-text-color);
  padding: 0px;
}

.calender-events-text {
  margin-left: 0px;
  /* padding: 15px; */
  background-color: #f6bd4b;
  background-color: var(--portal-theme-primary);
  color: white;
}
.small-contained-chip-success {
  background-color: #2e7d32;
  border: 1px solid #2e7d32;
  border-radius: 20px;
  color: #fff;
  font-size: 10px;
  padding: 1px 6px;
}
.fc-toolbar-chunk {
  display: -webkit-inline-flex;
  display: inline-flex;
}
/* calendar add event */
/* .css-1mav5hp-MuiPaper-root-MuiDrawer-paper {
  background-color: var(--sidebars-background-color);
  color: var(--input-text-color);
} */
/* .fc{
  padding: 10px;
    border-top-style: solid;
    border-color: var(--portal-theme-primary);
} */

.eventimage {
  width: 50px;
  height: 50px;
  margin-left: 5px;
  border: solid;
  border-color: #42969c;
}

.fc-daygrid-event {
  border: none !important;
  background: none !important;
}

.fc-today-button {
  background: none !important;
  color: #fff !important;
  color: var(--input-text-color) !important;
  border-style: none !important;
  font-weight: bold !important;
}

.fc-right {
  background: none !important;
  color: #fff !important;
  color: var(--input-text-color) !important;
  border-style: none !important;
}

.fc-prev-button {
  background: none !important;
  color: #fff !important;
  color: var(--input-text-color) !important;
  border-style: none !important;
}

.fc-right {
  background: none !important;
  color: #fff !important;
  color: var(--input-text-color) !important;
  border-style: none !important;
}

.fc-next-button {
  background: none !important;
  color: #fff !important;
  color: var(--input-text-color) !important;
  border-style: none !important;
}

.fc-dayGridMonth-button {
  background: none !important;
  color: #fff !important;
  color: var(--input-text-color) !important;
  border-style: solid !important;
  border-color: #ccc5c5 !important;
}

.fc-timeGridWeek-button {
  background: none !important;
  color: #fff !important;
  color: var(--input-text-color) !important;
  border-style: solid !important;
  border-color: #ccc5c5 !important;
}

.fc-timeGridDay-button {
  background: none !important;
  color: #fff !important;
  color: var(--input-text-color) !important;
  border-style: solid !important;
  border-color: #ccc5c5 !important;
}

/* .fc-col-header{
  border-bottom-style: solid !important;
}  */

.fc-col-header-cell-cushion {
  color: #fff;
  color: var(--input-text-color);
  text-decoration: none;
}

.fc-scrollgrid-section {
  border-style: none;
}

/* calendar day color end*/
.css-rbocvz-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover {
  will-change: background-color;
  background-color: #f6bd4b;
  background-color: var(--portal-theme-primary);
}

.fc-scrollgrid {
  /* border-top: none !important;  */
}

/* .fc-day-sun {
  border-bottom:thick !important;
    border-right-style: hidden !important;
    padding: 10px;
} 

.fc-day-mon {
  border-bottom: thick !important;
    border-right-style: hidden !important;
} 

.fc-day-tue {
  border-bottom: thick !important;
    border-right-style: hidden !important;
} 

.fc-day-wed {
  border-bottom: thick !important;
    border-right-style: hidden !important;
} 

.fc-day-thu {
  border-bottom: thick !important;
    border-right-style: hidden !important;
} 

.fc-day-fri {
  border-bottom: thick !important;
    border-right-style: hidden !important;
} 

.fc-day-sat {
  border-bottom: thick !important;
    border-right-style: hidden !important;
}  */

.fc-daygrid-day-number {
  text-decoration: none;
  color: #fff;
  color: var(--input-text-color);
  font-weight: bold;
}

.fc-daygrid-day-top {
  float: left !important;
}

.fc-scrollgrid-sync-table {
  border-top: thick !important;
}

.dropdownbutton {
  border-color: #ccc5c5 !important;
  font-size: 12px !important;
}
.modalIcon {
  cursor: pointer;
}
.preview span {
  position: absolute;
  right: 3px;
  top: -10px;
  font-size: 13px;
  font-weight: 700;
  height: 20px;
  z-index: 1;
  background-color: red;
  width: 20px;
  color: #fff;
  text-align: center;
  border-radius: 10px;
}
.preview img {
  height: 76px;
  width: 76px;
  border-radius: 5px;
  margin-right: 10px;
  display: inline;
}
.add-photo .preview span,
.add-memories-preview .preview span {
  top: -35px;
}
.preview span:hover {
  cursor: pointer;
}
.preview {
  position: relative;
}
.css-17svla2-MuiButtonBase-root-MuiChip-root {
  color: #fff;
  color: var(--input-text-color);
}
.custom-popover-box .remove-border {
  right: 25px;
  width: 30px;
  position: absolute;
  top: 50px;
}
.css-n1f7dt-MuiPaper-root-MuiCard-root {
  color: #fff;
  color: var(--input-text-color);
}
.upload-button label {
  text-align: center !important;
  /* background-color: rgba(175, 151, 102, 0.199); */
  background-color: rgba(175, 151, 102, 0.199);
  background-color: var(--portal-theme-secondary);
  padding: 15px;
  /* color: #f6bd4b; */
  color: #f6bd4b;
  color: var(--portal-theme-primary);
  border-radius: 5px;
  cursor: pointer;
  height: 76px;
  width: 76px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}
/* manage program access */
.css-1o0hybg-MuiDataGrid-root {
  color: #fff;
  color: var(--input-text-color);
}

.dialog-img {
  border: none;
  z-index: 1;
  top: 60%;
  left: "center";
}

.dialog-image {
  width: 400px;
  height: 400px;
}

.dialog-detail {
  border-color: #42969c;
  border-radius: 5px;
  position: static;
  z-index: 1;
  top: 100%;
  left: 20%;
  width: 400px;
}

.log-detail {
  width: 120px;
}
.social-set {
  border: solid;
  border-color: #ccc5c5;
  border-radius: 50px;
  display: -webkit-flex;
  display: flex;
}
.dl-button {
  /* border: thin; */
  border: 1px solid #ccc5c5;
  /* border-color: #ccc5c5; */
  padding: 5px;
  display: -webkit-flex;
  display: flex;
}
.dl-button2 {
  /* border: thin; */
  border: 1px solid black;
  /* border-color: #42969c; */
  padding: 5px;
  display: -webkit-flex;
  display: flex;
}

/* ====================================== for ipad screen ============================== */

/* @media only screen and (max-width: 768px) {
    .header-detail{
        align-items: center;
        height: 60px;
    }
    
    .header-task{
        display: flex;
        justify-content: space-evenly;
        border-right: 1px solid #c5c5c5;
        height: 100%;
    }
    
    .header-date{
        display: flex;
        justify-content: flex-end;
        border-right: 1px solid #c5c5c5;
        height: 100%;
    }
    
    .header-progress{
        border-right: 1px solid #c5c5c5;
        height: 100%;
        padding-top: 13.5px;
        padding-left: 3px;
    }
    
    .header-members{
        padding: 0px;
       
    }
} */
/* ====================================== for iphone screen ============================== */

/* @media only screen and (max-width: 375px) {
    .header-detail{
        align-items: center;
        height: 60px;
    }
    
    .header-task{
        display: flex;
        justify-content: space-evenly;
        border-right: 1px solid #c5c5c5;
        height: 100%;
    }
    
    .header-date{
        display: flex;
        justify-content: flex-end;
        border-right: 1px solid #c5c5c5;
        height: 100%;
    }
    
    .header-progress{
        border-right: 1px solid #c5c5c5;
        height: 100%;
        padding-top: 13.5px;
        padding-left: 3px;
    }
    
    .header-members{
        padding: 0px;
       
    }
} */

/* pinterest layout design */

.profile-details {
  font-weight: 500;
  font-size: 14px;
  margin-top: 12px;
}
.profile-details-padding {
  padding-left: 35px !important;
  padding-right: 35px !important;
}
.profile-heading {
  font-size: 30px;
  padding-left: 35px;
}
.add-category-button {
  margin-right: 44px;
  margin-bottom: 15px;
}
/* pinterest */
/* body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: sans-serif;
  } */
/* h1 {
    color: coral;
  } */
.quotes-heading {
  margin-left: 11px;
  /* margin-bottom: 20px; */
  line-height: 1.235;
  margin-bottom: 25px;
}
.grid-container {
  -webkit-columns: 3 200px;
          columns: 3 200px;
  grid-column-gap: 1.2rem;
  -webkit-column-gap: 1.2rem;
          column-gap: 1.2rem;
  width: 95%;
  margin: 0 auto;
}

.grid-container div {
  width: 150px;
  margin: 0 1.5rem 1.5rem 0;
  display: inline-block;
  width: 99%;
  border: 1px solid rgba(0, 0, 0, 0.125);
  /* padding: 0px; */
  /* box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 16px 32px -4px rgb(0 3 82 / 24%); */
  border-radius: 16px;
  transition: all 0.25s ease-in-out;
}
.grid-container div:hover img {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
}
.grid-container div:hover {
  /* box-shadow: 5px 5px 5px rgba(117, 117, 117, 0.5); */
  cursor: pointer;
}
.grid-container div img {
  width: 100%;
  /* filter: grayscale(100%); */
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  transition: all 0.25s ease-in-out;
}
.grid-container div p {
  margin: 4px 0;
  padding: 15px 15px 20px;
  /* padding-top: 9px; */
  text-align: left;
  /* font-style: italic; */
}

/* pinterest end */
@media screen and (max-width: 1024px) {
  .quotes-heading {
    margin-left: 10px;
    margin-bottom: 40px;
  }
  .add-category-button {
    margin-right: 20px;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 769px) {
  .profile-button-padding {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .quotes-heading {
    margin-left: 7px;
    margin-bottom: 40px;
  }
  .add-category-button {
    margin-right: 40px;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 376px) {
  .profile-button {
    margin-bottom: 20px;
    width: 150px;
    margin-left: -10px;
  }
  .profile-image {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .profile-heading {
    font-size: 30px;
    padding-left: 0px;
  }
  .profile-button-padding {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .profile-details-padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .quotes-heading {
    margin-left: 6px;
    margin-bottom: 40px;
  }
  .add-category-button {
    margin-right: 19px;
    margin-bottom: 15px;
  }
}

.menus-list {
  padding-top: 12px;
  padding-bottom: 12px;
  color: white;
  color: var(--sidebars-text-color);
}

.navbar-icon {
  color: #f6bd4b;
  color: var(--portal-theme-primary);
  margin-right: 16px;
}
.css-1ky212h {
  background-color: white;
}

.css-1c1nuln {
  color: #fff;
  color: var(--input-text-color);
}
.tox .tox-notification--warn,
.tox .tox-notification--warning {
  display: none !important;
}
.css-1pacybd,
.css-1n1gvhf {
  color: #fff !important;
  color: var(--input-text-color) !important;
}
.css-15ynx34,
.css-2tnm32 {
  background-color: #1d1c1d;
  background-color: var(--sidebars-background-color);
  color: #fff;
  color: var(--input-text-color);
}

.css-rbocvz-MuiButtonBase-root-MuiPickersDay-root:focus,
.css-14ewge7:focus {
  /* background-color: var(--sidebars-background-color); */
  color: #fff;
  color: var(--input-text-color);
}
.css-1fki5np {
  color: #fff !important;
  color: var(--input-text-color) !important;
}
.css-2tnm32.Mui-selected:hover {
  will-change: background-color;
  background-color: #f6bd4b;
  background-color: var(--portal-theme-primary);
}
.css-1l8cwz6-MuiToolbar-root,
.css-1r5qqep {
  background-color: #1d1c1d;
  background-color: var(--sidebars-background-color);
}
.css-rbocvz-MuiButtonBase-root-MuiPickersDay-root:focus.Mui-selected,
.css-2tnm32:focus.Mui-selected {
  will-change: background-color;
  background-color: #f6bd4b;
  background-color: var(--portal-theme-primary);
}
.css-1uc7r6i {
  /* background-color: var(--sidebars-background-color); */
  color: #fff;
  color: var(--input-text-color);
}
.css-1aya31c {
  color: #fff;
  color: var(--input-text-color);
}
.css-1clbjpy {
  background-color: #1d1c1d;
  background-color: var(--sidebars-background-color);
  color: #fff;
  color: var(--input-text-color);
}
.css-ep587z-MuiTableCell-root {
  color: #fff !important;
  color: var(--input-text-color) !important;
}
.css-eryebb,
.css-9dfmxx,
.css-66mibm-MuiButtonBase-root-MuiTableSortLabel-root,
.css-cdr993-MuiTablePagination-root {
  color: #fff;
  color: var(--input-text-color);
}
.css-14ewge7:focus.Mui-selected {
  will-change: background-color;
  background-color: #f6bd4b;
  background-color: var(--portal-theme-primary);
}
.bg-image {
  background-image: url("https://img.freepik.com/free-vector/hand-painted-watercolor-pastel-sky-background_23-2148902771.jpg?w=2000");
}
/* .css-1jlyzur-MuiButtonBase-root-MuiMenuItem-root {
  color: var(--popup-text-color);
} */
/* table header when selection */
.css-lau5ov-MuiToolbar-root {
  background-color: #1d1c1d;
  background-color: var(--sidebars-background-color);
}
.question-survey {
  margin-top: 11px;
  margin-left: 40px;
  font-size: 30px;
}
.question-background {
  /* background-color: var(--sidebars-background-color); */
  border-radius: 10px;
}
.question-background-light {
  background-color: #312f2f;
  z-index: 0;
}
.nested-child-menu {
  background-color: "#1d1c1d";
  background-color: var(--audio-color);
  padding: 10px 10px 10px 10px;
}
.inputs-fields {
  color: white;
  color: var(--sidebars-text-color);
}
.css-q0ekve-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover,
.css-q0ekve-MuiButtonBase-root-MuiPickersDay-root:focus.Mui-selected,
.css-15lp6uz.Mui-selected:hover,
.css-15lp6uz:focus.Mui-selected {
  will-change: background-color;
  background-color: #f6bd4b;
  background-color: var(--portal-theme-primary);
}
.css-dv7nb .MuiAutocomplete-option {
  background-color: white !important;
}
.muiAutocomplete-popper {
  background-color: white !important;
}
/* auto complete background color */
.css-dv7nb {
  background-color: white;
}
.css-8ibnz1 {
  color: black;
  background-color: white;
}
.css-1etwmpy.Mui-selected:hover,
.css-1etwmpy:focus.Mui-selected {
  will-change: background-color;
  background-color: #f6bd4b;
  background-color: var(--portal-theme-primary);
}
.css-1brzq0m {
  background-color: black;
  background-color: var(--background-primary-color);
}
.mui-without-bg-custom-table .mui-table-footer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.sale-page-title {
  color: #f6bd4b;
  color: var(--portal-theme-primary);
  font-size: 23px;
  margin-left: 20px;
}
.image-background {
  background-color: lightgray;
  border-radius: 4px;
}
.css-340559 {
  background-color: #1d1c1d !important;
  background-color: var(--sidebars-background-color) !important;
}
.css-13vjeky,
.css-ypofq1-MuiDataGrid-toolbarContainer {
  -webkit-justify-content: end !important;
          justify-content: end !important;
}
.dataGridStyle-news-letter {
  background-color: white;
  box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 16px 32px -4px rgb(0 3 82 / 24%);
}
#fixedbutton {
  bottom: 30px;
  position: fixed;
  right: 20px;
  z-index: 11111111;
}
.MuiTablePagination-toolbar {
  -webkit-align-items: baseline !important;
          align-items: baseline !important;
}
.mui-without-bg-custom-table .mui-table-footer .rows_selected_text {
  color: #f6bd4b;
  color: #f6bd4b;
  color: var(--portal-theme-primary);
  font-size: 1rem;
  font-weight: 400;
  padding-left: 20px;
  padding-top: 15px;
}
.css-14ewge7:hover,
.css-1m6yy9o,
.css-2tnm32.Mui-selected,
.css-9dfmxx {
  color: #fff !important;
  color: #fff !important;
  color: var(--input-text-color) !important;
}
.rti--container {
  --rti-bg: #fff !important;
  --rti-s: 17px !important;
  --rti-tag: #f6bd4b !important;
  --rti-tag-remove: #e53e3e !important;
  --rti-main: #bc9c22 !important;
  line-height: 1 !important;
}
.tox-notifications-container {
  visibility: hidden !important;
}
.image-border {
  border: 2px solid #cacaca;
  /* background-color: #cacaca; */
}
.sidebar-search-box {
  position: relative;
}
.sidebar-search-icon {
  position: absolute;
  left: 10px;
  top: 10px;
  color: #f6bd4b;
  color: var(--portal-theme-primary);
}
.sidebar-search-input {
  width: 100%;
  border: solid 2px #f6bd4b;
  border: solid 2px var(--portal-theme-primary);
  height: 40px;
  border-radius: 25px;
  background-color: transparent;
  padding-left: 40px;
  color: white;
  outline: none !important;
}

/* clear fix */
.grid:after {
  content: "";
  display: block;
  clear: both;
}

/* ---- .grid-item ---- */

.grid-item {
  width: 33.333%;
}

.grid-item {
  float: left;
}

.grid-item img {
  display: block;
  max-width: 100%;
}
.menus-child-items {
  padding-left: 30px;
  font-size: 15px;
}
.pdf-nav {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 10px;
}
.pdf-view {
  width: 600px;
  text-align: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: auto;
}
.pdf-pages {
  margin-left: 10px;
  margin-top: 5px;
  /* display: flex;
  justify-content: flex-end; */
}

